<template>
  <v-dialog
    v-model="dialogDelete"
    max-width="500px"
  >
    <v-card>
      <v-card-title
        class="headline"
      >
        Supprimer ce cours ?
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="gray"
          @click="closeDelete"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          @click="deleteItemConfirm"
        >
          Supprimer
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {

    name: 'DialogDelete',

    data() {
      return {
        dialogDelete: false,
      }
    },

    methods: {
      deleteItem (item) {
        this.editedItem = item;
        this.dialogDelete = true;
      },

      deleteItemConfirm () {
        this.axios.delete('/cours/' + this.editedItem.id)
          .then((res) => {
            this.setSnack({ text: 'Le cours a bien été retiré du catalogue', type: 'success' });
            this.$emit('courseDeleted', this.editedItem);
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la suppression du cours', type: 'warn' });
          });
        this.closeDelete();
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),

    },

  }
</script>
